import { Transform } from 'class-transformer';
import { transformTimestampToDate } from 'models';

export type NorbyFeature =
  | 'inbox'
  | 'instagramIntegration'
  | 'signupsFlowForm'
  | 'newsletterV2'
  | 'chatRecommendations'
  | 'langchainPoweredChat';

export const NORBY_FEATURES: NorbyFeature[] = [
  'inbox',
  'instagramIntegration',
  'signupsFlowForm',
  'newsletterV2',
  'chatRecommendations',
  'langchainPoweredChat'
];

export class IFeaturePermission {
  id: string;
  feature: NorbyFeature;
  permitted: boolean;
  slug: string;
  createdAtCursor: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  permittedAt: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  createdAt: Date;
}
